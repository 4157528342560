import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [prompt, setPrompt] = useState('');
  // Updated responses state to hold both response and model
  const [responses, setResponses] = useState([]);
  const [streaming, setStreaming] = useState(false);
  const [selectedModel, setSelectedModel] = useState('aeblog-v2');
  const baseUrl = "https://www.doyouusethejuice.com"

  const handleSubmit = () => {
    console.log('baseUrl'+baseUrl);
    console.log("process.env.RAILWAY_PUBLIC_DOMAIN "+process.env.RAILWAY_PUBLIC_DOMAIN)
    setStreaming(true);

    let newResponse = { response: '', model: selectedModel };  // Include the model
    let newResponses = [...responses, newResponse];

    const eventSource = new EventSource(`${baseUrl}/chat?prompt=${prompt}&model=${selectedModel}`);

    eventSource.onmessage = function(event) {
      const dataWithRealNewlines = event.data.replace(/\\n/g, '\n');
      newResponse.response += dataWithRealNewlines;
      newResponses[newResponses.length - 1] = newResponse;
      setResponses([...newResponses]);
    };

    eventSource.onerror = function(error) {
      eventSource.close();
      setStreaming(false);
    };

    setResponses(newResponses);
  };

  const removeResponse = (index) => {
    setResponses((prevResponses) => prevResponses.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const container = document.querySelector('.response-container');
    container.scrollTop = container.scrollHeight;
  }, [responses]);

  return (
    <div className="App">
      <div className="input-container">
        <h1>The Juice</h1>
        <textarea
          className="textarea"
          rows="4"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
          placeholder="Enter your prompt here"
        />
        </div>
        <div className='input-container'>
        <select className='model-select' onChange={e => setSelectedModel(e.target.value)} value={selectedModel}>
          <option value="aeblog-v2">AE Blog v2</option>
          <option value="greg-v3">Greg v3</option>
        </select>
        <button className='submit' onClick={handleSubmit} disabled={streaming}>
          Submit
        </button>
      </div>
      <h2>Responses:</h2>
      <div className="response-container">
        {responses.map((item, index) => (
          <div key={index}>
            <button onClick={() => removeResponse(index)}>X</button>
            {/* Display the model in light gray */}
            <span style={{ color: 'lightgray', marginLeft: '10px' }}>{item.model}</span>
            <hr />
            <p className='response'>{item.response}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
